import React, { useLayoutEffect, useRef } from "react";
import CardEvent from "../components/CardEvent";
import { Helmet } from 'react-helmet';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Hero from "../components/Hero";
import heroImage from "../img/heros/IMG_10.jpg";
gsap.registerPlugin(ScrollTrigger);

const Gigs = () => {
  const cards = useRef([]);
  cards.current = [];

  useLayoutEffect(() => {
    cards.current.map((item, index) => {
      gsap.fromTo(item,
        {
          opacity: 0
        },
        {
          scrollTrigger: {
            trigger: item,
            start: "-100 70%",
            end: "bottom top",
          },
          duration: 2,
          opacity: 1,
          ease: "power2",
        }
      );
    });
  });

  const card = (item) => {
    if (item) {
      cards.current.push(item);
    }
  };
  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://skunksters.com/Gigs" />
      </Helmet>
      <Hero image imageSrc={heroImage} />
      <div className="app__background">
        <div className="app__layout">
          <h1 className="card__title--large">Follow the scent near you</h1>
          <div ref={card}>
            <CardEvent
              date="SAT, 31 AUG 2024 AT 19:15"
              title="Kidderminster Music Fest · Ye Olde Seven Stars"
              location="See venue details"
              locationLink="https://www.facebook.com/YeOldeSevenStars"
            />
          </div>
          <div ref={card}>
            <CardEvent
              date="SAT, 20 SEP AT 21:00"
              title="The Golden Cross Inn · Hereford"
              location="See venue details"
              locationLink="https://www.facebook.com/profile.php?id=100056370735545"
            />
          </div>
          <div ref={card}>
            <CardEvent
              date="SAT, 02 NOV AT 21:30"
              title="The Black Star · Stourport"
              location="See venue details"
              locationLink="https://www.theblackstar.co.uk/"
            />
          </div>
          <div ref={card}>
            <CardEvent
              date="SAT, 16 NOV AT 22:00"
              title="The Valkyrie Bar · Evesham"
              location="See venue details"
              locationLink="https://www.facebook.com/events/941937124155076"
            />
          </div>
          <div ref={card}>
            <CardEvent
              date="SAT, 7 DEC AT 20:30"
              title="Live at The Plough Inn · Upton-On-Severn"
              location="See venue details"
              locationLink="https://www.facebook.com/events/1420248332247708"
            />
          </div>
          <div ref={card}>
            <CardEvent
              date="Looking for more events?"
              title="See our facebook events"
              location="Facebook events page"
              locationLink="https://www.facebook.com/kalashnikov.skunk/events"
              facebook="true"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Gigs;
